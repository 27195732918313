<template>
  <div class="card-container">
    <!-- <p class="title">Verify Certificate</p> -->
    <div class="columns">
      <div class="column center-imp">
        <div id class>
          <b-field>
            <b-upload v-model="file" @input="onFileLoad(file)" drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>Drop your Certificate file here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-modal width="400px" @close="uploadErrorClose" :active.sync="isPDF">
            <div class="box">
              <p class="card-container center-imp">
                <strong class="has-text-danger">
                  Only PDF files upto 2.5MB are supported. Encrypted PDF Files
                  are not supported.
                </strong>
              </p>
              <p class="on-end">
                <button class="button" type="button" @click="uploadErrorClose">
                  Ok
                </button>
              </p>
            </div>
          </b-modal>
          <div class="tags" v-if="file">
            <b-tooltip :label="file.name" type="is-dark" position="is-top">
              <span class="tag is-rounded is-secondary">
                <span class="truncate">{{ file.name }}</span>

                <button class="delete is-small" type="button" @click="deleteDropFile()"></button>
              </span>
            </b-tooltip>
          </div>
          <div class="tags" v-else-if="currentFileName">
            <b-tooltip :label="currentFileName" type="is-dark" position="is-top">
              <span class="tag is-rounded is-secondary">
                <span class="truncate">{{ currentFileName }}</span>

                <button class="delete is-small" type="button" @click="deleteDropFile()"></button>
              </span>
            </b-tooltip>
          </div>
          <div class="tags" v-else>
            <span class="tag is-rounded is-white">
              <span class="truncate"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-message title="Certificate Verified" :active.sync="isVerified" type="is-success" :closable="false" has-icon
          size="is-small">
          <p>Certificate ID: {{ certID }}</p>
          <p>Certificate Hash: {{ certHash }}</p>
          <p>WorkFlow ID: {{ workflowID }}</p>
          <p>Token ID: {{ tokenID }}</p>
        </b-message>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-message title="Certificate Verfication Failed" :active.sync="verifyFalied" type="is-danger" :closable="false"
          has-icon>
          The certificate is either corrupt or invalid.
        </b-message>
      </div>
    </div>
  </div>
</template>
<script>
import { globalFunction } from "../services/global.function";
export default {
  data() {
    return {
      file: null,
      isPDF: false,
      isLoading: true,
      currentFileName: false,
      certID: '',
      certHash: '',
      workflowID: '',
      tokenID: '',
      isVerified: false,
      verifyFalied: false
    };
  },
  props: {},
  mounted() { },
  computed: {},
  watch: {},
  methods: {
    onFileLoad: async function (file) {
      try {
        this.isVerified = false;
        this.verifyFalied = false;
        console.log("Console log : onFileLoad -> file", this.file);
        if (file.size > 2621440) {
          console.log("file too big, setting isPDF to true");
          this.isPDF = true;
          return;
        }
        if (file.type === "application/pdf") {
          const fileHash = await globalFunction.fileHash(file);
          console.log("file hash:", fileHash);
          try {
            const resp = await this.$store.getters.authFlowObject.getReq(
              "/certificate/verify",
              {
                hash: fileHash,
                hash_algorithm: "sha256",
              }
            );
            console.log("resp:", resp);
            if (resp.status == 200) {
              this.certID = resp.data.certificate_id;
              this.certHash = resp.data.hash_algorithm + ":" + resp.data.hash.slice(0, 5) +
                "..." + resp.data.hash.slice(-5);
              this.workflowID = resp.data.workflow_id;
              this.tokenID = resp.data.token_id;
              this.isVerified = true;
            } else {
              this.verifyFalied = true;
            }
          } catch (error) {
            this.verifyFalied = true;
          }
          this.isLoading = false;
        } else {
          console.log(
            "%c Console Log : PDF Not Supported  ",
            "background: #222; color: #D10000"
          );
          this.isPDF = true;
        }
      } catch (error) {
        console.log("file verify error:", error);
        this.isLoading = false;
        globalFunction.notify("is-danger", "certificate verification failed");
      }
    },
    uploadErrorClose: async function () {
      this.isPDF = false;
      this.deleteDropFile();
    },
    deleteDropFile() {
      console.log("resetting state after file delete");
      this.file = null;
      this.isVerified = false;
      this.verifyFalied = false;
    },
  },
};
</script>
